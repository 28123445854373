import TextField from "@material-ui/core/TextField";
import React from "react";

const EditComment = React.forwardRef(({ handleSaveEdit, handleCancel, data, isReplyToReply}, ref) => {
    const [tempEditCommentText, setTempEditCommentText] = React.useState("");
    React.useImperativeHandle(ref, () => ({
        getMessage() {
            return tempEditCommentText;
        },
        clearMessage(){
            setTempEditCommentText("");
        }
    }));

    return (
        <div className="edit-comment">
            <TextField multiline
                       defaultValue={data.text}
                       className="text-field"
                       onChange={e => setTempEditCommentText(e.target.value)}
                       inputRef={(input) => input && input.focus()}
                       onFocus={(e) =>
                           e.currentTarget.setSelectionRange(
                               e.currentTarget.value.length,
                               e.currentTarget.value.length
                           )}
                       minRows={1}
                       maxRows={4}/>
            <button className="save" onClick={e => handleSaveEdit(e)}>{isReplyToReply ? "Post Reply": "Save"}</button>
            <button className="cancel" onClick={(e) => handleCancel(e)}>Cancel</button>
        </div>
    )
});

export default EditComment;

import React, {useRef, useState} from "react";
import {Grid} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

// Components
import CreateReply from "./reply-section/create-reply";
import MessageContent from "./message-content";
import EditComment from "./comment-box/edit-comment";
import CommentReplies from "./reply-section/comment-replies";

import FastAPIClient from "../../../../../auth/fast_api_auth";

const areEqual = (prevProps, nextProps) => true;

const Comment =
    React.memo(
        React.forwardRef(({data,
                            handleClick,
                            deleteSelectedComment,
                            index,
                            setDeleteItem,
                            user,
                            replies,
                            schoolId}, ref) => {
  const [isEdit, setIsEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [paginate, setPagination] = useState(2);
  const commentReplies = useRef();
  const editComment = useRef();

  let client = new FastAPIClient(schoolId);

  React.useImperativeHandle(ref, () => ({
    newReply() {
      commentReplies.current.setLoading();
    }
  }));

  // SAVING EDITED COMMENT
  const handleSaveEdit = async () => {
    const text = editComment.current.getMessage();
    const payload = {
      text,
      comment_id: data.id,
    }

    try {
      const res = await client.updateComment(payload);
      if(res){
        data.text = text;
        setIsEdit(false);
      }
    } catch (e) {
      console.log(`Error: ${e}`);
    }
  }

  const handleDeleteComment = async () => {
    setOpenDialog(true);
  }

  const deleteComment = async (e) => {
    e.preventDefault();
    setDeleteItem(index)
    deleteSelectedComment(data, index);
  }

  const getInitials = (string) => {
    let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const isMyComment = user?.id === data?.commenter_id;

  return (
      <Grid container wrap="nowrap" spacing={2} key={`comment-${data?.id}`} className="comment-content">
        {/* COMMENtS AREA*/}
        {constructMessage(
            isEdit,
            data,
            isMyComment,
            handleDeleteComment,
            setIsEdit,
            handleSaveEdit,
            getInitials,
            handleClick,
            editComment)}
        <CommentReplies ref={commentReplies}
                        repliesData={replies}
                        getInitials={getInitials}
                        handleClick={handleClick}
                        deleteSelectedComment={deleteSelectedComment}
                        user={user}
                        paginate={paginate}
                        setPagination={setPagination}
                        schoolId={schoolId}
        />

        {/* REPLIES AREA*/}
        <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this comment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              No
            </Button>
            <Button onClick={e => deleteComment(e)} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

      </Grid>
  )
}),
        areEqual);

let constructMessage = (isEdit,
                        data,
                        isMyComment,
                        handleDeleteComment,
                        setIsEdit,
                        handleSaveEdit,
                        getInitials,
                        handleClick,
                        editComment) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleEdit = () => {
    setIsEdit(!isEdit)
    handleClose();
  };

  const handleClose = () => setAnchorEl(null)

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEdit(false)
  }

  return (
      <div className="comment">
        <Grid item className="user-image">
          <div className="circle">
            <span className="initials">{getInitials(data?.commenter_name)}</span>
          </div>
        </Grid>
        <Grid  item xs zeroMinWidth className="comment-area">
          {!isEdit && <div className="header-comment">
            <MessageContent data={data}/>
            { isMyComment && <a className="custom-icon edit pointer-cursor"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={(e) =>
                                    setAnchorEl(e.currentTarget)
                                }/>}
            {MenuContent({anchorEl, handleClose, handleEdit, handleDeleteComment})}
          </div>}
          <div className="comment-section">
            {!isEdit && <p style={{textAlign: "left"}} className="message">
              {data?.text}{" "}
            </p>}
            {isEdit && <EditComment ref={editComment}
                                    handleSaveEdit={handleSaveEdit}
                                    handleCancel={handleCancel}
                                    data={data} />}
          </div>
          {!isEdit && <a onClick={(e) => handleClick(e, data.id)}
                         className="reply-button-in-a-reply">Reply</a>}
        </Grid>
      </div>
  )
}

let MenuContent = ({anchorEl, handleClose, handleEdit, handleDeleteComment}) => {
  return <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      TransitionComponent={Fade}
  >
    <MenuItem onClick={handleEdit} className="header-comment-icon">
      <a className="custom-icon edit-pencil pointer-cursor"/>  Edit
    </MenuItem>
    <MenuItem onClick={handleDeleteComment} className="header-comment-icon">
      <a className="custom-icon remove pointer-cursor"/>   Delete
    </MenuItem>
  </Menu>
}

let CommentsContent = React.memo(({data,
                                    course,
                                    deleteSelectedComment,
                                    index,
                                    setDeleteItem,
                                    user,
                                    lecture,
                                    schoolId}) =>
{
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [replies] = useState(data.replies);
  const [isReply, setIsReply] = useState({displayReply: false, id: null});
  let client = new FastAPIClient(schoolId);
  const postComment = useRef();
  const commentsRef = useRef();

  let handleClick = (e, data) => {
    e.preventDefault();
    setIsReply({displayReply: true, id: data})
  }

  const submitMessage = async (e) => {
    e.preventDefault();

    commentsRef.current.newReply();

    setIsLoading(true);
    const payload = {
      text: postComment.current.getMessage(),
      course_id: course.id,
      commenter_id: user.id,
      lecture_id: lecture?.id,  // CMS reference
      parent_id: data.id
    };

    try {
      const res = await client.createComment(payload);
      if(res){
        res.data.isDisplay = true;
        replies.unshift(res.data);
        commentsRef.current.newReply();
        setMessage("");
        postComment.current.clearMessage();
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false)
      console.log(`Error: ${e}`);
    }
  }

  const cancel = () => {
    setIsReply({displayReply: false, id: null});
  };

  return (
      <>
        <Comment ref={commentsRef}
                 data={data}
                 replies={replies}
                 handleClick={handleClick}
                 setDeleteItem={setDeleteItem}
                 deleteSelectedComment={deleteSelectedComment}
                 index={index}
                 user={user}
                 schoolId={schoolId}
                 isLoading={isLoading}/>
        { isReply.displayReply && <CreateReply ref={postComment}
                                               key={data.id}
                                               submitMessage={submitMessage}
                                               user={user}
                                               message={message}
                                               setMessage={setMessage}
                                               isLoading={isLoading}
                                               cancel={cancel} />
        }
      </>
  )
}, areEqual);

export default CommentsContent

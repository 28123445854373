import React, {useState} from "react";
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {faComments} from "@fortawesome/free-solid-svg-icons";
import RepliesContent from "./replies-content";
import FastAPIClient from "../../../../../../auth/fast_api_auth";
import Loader from "../../../shared-components/Loader";

const areEqual = (prevProps, nextProps) => true;

// REPLY
const CommentReplies = React.memo(React.forwardRef(({repliesData,
                                                          getInitials,
                                                          deleteSelectedComment,
                                                          user,
                                                          schoolId
                                                      }, ref) => {
    const replyDiv = React.useRef();

    const [openDialog, setOpenDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedComment, setSelectedComment] = useState({});
    const [deleteIndex, setDeleteIndexItem] = useState(null);
    const [paginate, setPagination] = useState(2);
    let client = new FastAPIClient(schoolId);

    React.useImperativeHandle(ref, () => ({
        setLoading() {
            return setIsLoading(!isLoading);
        }
    }));


    const handleDeleteComment = async (e,index,data) => {
        setSelectedComment(data);
        setDeleteIndexItem(index)
        setOpenDialog(true);
    };

    const deleteComment = async (e) => {
        e.preventDefault();
        repliesData.splice(deleteIndex, 1);
        deleteSelectedComment(selectedComment, deleteIndex);
        setOpenDialog(false);
    };

    const handleCancel = (e) => {
        e.preventDefault();
        replyDiv.current.reset();
    };

    const handleEdit = (e, data) => {
        e.preventDefault();
        setSelectedComment(data);
        replyDiv.current.closeAnchor();
    };

    // SAVING EDITED COMMENT
    const handleSaveEdit = async () => {
        const data = replyDiv.current.getPayload();

        const text = data.text;
        const payload = {
            text,
            comment_id: data.selectedComment.id,
        };

        try {
            const res = await client.updateComment(payload);
            if(res){
                repliesData[data.selectedIndex].text = data.text;
                replyDiv.current.reset();
            }
        } catch (e) {
            console.log(`Error: ${e}`);
        }
    };

    const manageDataToDisplay = (e) => {
        e.preventDefault();
        setPagination(paginate + 2);
        let paginateNumber = paginate + 2;
        repliesData.forEach((data, index) => {
            if(paginateNumber >= index){
                data.isDisplay = true;
            } else {
                data.isDisplay = false;
            }
        });
    };

    let isShowMoreDisplayed = repliesData.length > 2 && paginate < repliesData.length;

    return (
        <div className="reply-content">
            { repliesData.length > 0 && repliesData.map((data, index) => {
                return (
                    !isLoading && data.isDisplay && <div key={`reply-text-${index}${data.id}`} >
                        <RepliesContent ref={replyDiv}
                                        getInitials={getInitials}
                                        selectedComment={selectedComment}
                                        setSelectedComment={setSelectedComment}
                                        data={data}
                                        user={user}
                                        index={index}
                                        handleCancel={handleCancel}
                                        handleEdit={handleEdit}
                                        handleDeleteComment={handleDeleteComment}
                                        handleSaveEdit={handleSaveEdit}
                        />
                    </div>
                )
            })}
            {!isLoading && isShowMoreDisplayed &&
            <div className="show-more">
                <a onClick={(e) => manageDataToDisplay(e)}>
                    <FontAwesomeIcon icon={faComments} className="left-icon" />
                    Show more replies
                </a>
            </div>}

            <Dialog
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this comment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        No
                    </Button>
                    <Button onClick={e => deleteComment(e)} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            {isLoading && <div className="fetching-comments">
                <Loader /> Fetching Replies
            </div>}
        </div>
    )
}), areEqual);

export default CommentReplies;

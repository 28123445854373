import React from "react";
import {isEmpty} from "lodash";
import {Grid} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import MenuItem from "@material-ui/core/MenuItem";

import MessageContent from "../message-content";
import EditComment from "../comment-box/edit-comment";

const RepliesContent = React.forwardRef(({getInitials,
                                             data,
                                             user,
                                             index,
                                             handleCancel,
                                             handleDeleteComment,
                                             handleSaveEdit,
                                             selectedComment,
                                             setSelectedComment,
                                             handleEdit
}, ref) => {
    const editComment = React.useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);

    //const [selectedComment, setSelectedComment] = React.useState({});
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    const handleSettings = (e, index) => {
        e.preventDefault();
        setSelectedIndex(index);
        setAnchorEl(e.currentTarget);
    };


    const handleClose = () => setAnchorEl(null);

    React.useImperativeHandle(ref, () => ({
        getPayload() {
            return {
                selectedIndex,
                selectedComment,
                text: editComment.current.getMessage()
            };
        },
        reset(){
            //setIsEdit(false);
            setSelectedIndex(null);
            setSelectedComment({});
        },
        closeAnchor(){
            setAnchorEl(null);
        }
    }));

    return (
        <div className="comment">
            <Grid item className="user-image">
                <div className="circle">
                    <span className="initials">{getInitials(data?.commenter_name)}</span>
                </div>
            </Grid>
            <Grid  item xs zeroMinWidth className="comment-area">
                {selectedComment.id !== data.id && <div className="header-comment">
                    <MessageContent data={data}/>
                    {/*SETTINGS*/}
                    {user?.id === data?.commenter_id && <a className="custom-icon edit pointer-cursor"
                                                         aria-controls="simple-menu"
                                                         aria-haspopup="true"
                                                         onClick={(e) =>
                                                             handleSettings(e, index)
                                                         }/>}
                    {MenuContent({anchorEl, handleClose, handleEdit, handleDeleteComment, data, index})}
                </div>}
                <div className="comment-section">
                    {selectedComment.id !== data.id && <p style={{textAlign: "left"}} className="message">
                        {data.text}{" "}
                    </p>}
                    {selectedComment.id === data.id &&
                    <EditComment ref={editComment}
                                 handleSaveEdit={handleSaveEdit}
                                 handleCancel={handleCancel}
                                 data={data} />}
                </div>
            </Grid>
        </div>
    )
});

let MenuContent = ({anchorEl, handleClose, handleEdit, handleDeleteComment, data, index}) => {
    return <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "top",
            horizontal: "right"
        }}
        TransitionComponent={Fade}
    >
        <MenuItem onClick={(e) => {handleEdit(e, data, index)}} className="header-comment-icon">
            <a className="custom-icon edit-pencil pointer-cursor"/>  Edit
        </MenuItem>
        <MenuItem onClick={(e) => handleDeleteComment(e, index, data)} className="header-comment-icon">
            <a className="custom-icon remove pointer-cursor"/>   Delete
        </MenuItem>
    </Menu>
}

export default RepliesContent;

import React from 'react';
import Services from "./services";

const MessageContent = ({data}) => {
  return (
    <div className="section">
      <h4 className="user-name">{data.commenter_name}</h4>
      <p className="time">
          {data?.updated_timestamp && Services.TimeAgo(data.updated_timestamp)}
      </p>
    </div>
  )
}

export default MessageContent;

import React, { useState, useEffect, useRef } from 'react';
import {isEmpty} from 'lodash';
import ReactPaginate from 'react-paginate';
import { Paper } from "@material-ui/core";
import "../../../../stylesheets/_icons.css"
import "../../../../stylesheets/index.css"
import "../../../../css/components/comments.css"

// Components
import FastAPIClient from "../../../../../auth/fast_api_auth";
import CommentsContent from "./comments-content";
import CreateComment from "./comment-box/create-comment";
import Loader from "../../shared-components/Loader";
import {isAuthenticated, commentsFromJWT} from "../../auth/auth";
import LayoutLecture from "@coursemaker/gatsby-theme-coursemaker/src/components/layout-lecture";

const LectureComments = ({ lecture, course, schoolId }) => {
  if(!isAuthenticated()) {
    return <></>;
  }
  // scenario: user has JWT set. Author toggles comments off.
  // comments load and attempt to fetch from API. 403.
  if (!commentsFromJWT().includes(parseInt(course?.id))){
    return <></>;
  }
  const postComment = useRef();
  const [messageIsLoading, setMessageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [deleteIndex, setDeleteIndexItem] = useState(null);
  const [user, setUserLoggedIn] = useState({});
  const [pagination, setPagination] = useState([]);

  let client = new FastAPIClient(schoolId);

  const deleteSelectedComment = async (comment) => {
    const payload = {comment_id: comment.id};
    const commentIndex = data.comments.findIndex(data => data.id === comment.id);

    try {
      const res = await client.deleteComment(payload);
      if(res) {
        data.comments.splice(commentIndex, 1);
        setDeleteIndexItem(null);
      }

    } catch (e) {
      console.log(`Error: ${e}`);
      setIsLoading(false);
    }
  }

  const submitMessage = async () => {
    setMessageLoading(true)

    const user = JSON.parse(localStorage.getItem("user"))
    const payload = {
      text: postComment.current.getMessage(),
      course_id: course.id,
      commenter_id: user.id,
      lecture_id: lecture?.id,
      parent_id: null
    }

    try {
      const res = await client.createComment(payload);
      if(res){
        setTimeout(function(){
          let dataTemp = data;
          dataTemp.comments.unshift(res.data);
          setData(dataTemp);
          postComment.current.clearMessage();
          setMessageLoading(false);
        }, 1000);

      }
    } catch (e) {
      setIsLoading(false)
      console.log(`Error: ${e}`);
    }
  };

  // Async loader
  // Check if comments enabled
  // If not show "Comments disabled"
  // If yes show comments with pagination
  useEffect(() => {
    // Repeated because: https://stackoverflow.com/questions/63570597/typeerror-func-apply-is-not-a-function
    const fetchDataInner = async (course, page_number) => {
      setIsLoading(true);
      const payload = {
        course_id: course?.id,
        lecture_id: lecture?.id,
        page_number
      }
      client.fetchComments(payload).then((result) => {
        if(result && !isEmpty(result?.comments)){
          setPagination(result.pagination_parameters);
          result.comments.map(data => {
            data.replies.map((comment, index) => {
              if(2 >= index){
                comment.isDisplay = true;
              } else {
                comment.isDisplay = false;
              }
            });
          })
          setData(result);
          setIsLoading(false);
        } else {
          setData({
            comments: [],
            pagination_parameters: {
              page_number: 0,
              page_size: 0,
              num_pages: 0,
              total_results: 0
            }
          });
          setIsLoading(false);
        }
      }, (err) => {
        setData({
          comments: [],
          pagination_parameters: {
            page_number: 0,
            page_size: 0,
            num_pages: 0,
            total_results: 0
          }
        });
        setIsLoading(false);
        console.error(`Failed to fetch comments. ${err}`);
      })
    }
    if (typeof window !== 'undefined') {
      const user = JSON.parse(localStorage.getItem("user"))
      setUserLoggedIn(user);
      fetchDataInner(course, 1);
    } else {
      setData({
        comments: [],
        pagination_parameters: {
          page_number: 0,
          page_size: 0,
          num_pages: 0,
          total_results: 0
        }
      });
      setIsLoading(false);
    }
  }, [setUserLoggedIn]);

  const handlePageClick = (data) => {
    let selected = data.selected;
    const page = selected + 1;
    fetchData(course, page);
  };

 const fetchData = (course, page_number) => {
   setIsLoading(true);
   const payload = {
     course_id: course?.id,
     lecture_id: lecture?.id,
     page_number
   }
   client.fetchComments(payload).then((result) => {
     if(result && !isEmpty(result?.comments)){
       setPagination(result.pagination_parameters);
       result.comments.map(data => {
         data.replies.map((comment, index) => {
           if(2 >= index){
             comment.isDisplay = true;
           } else {
             comment.isDisplay = false;
           }
         });
       })
       setData(result);
       setIsLoading(false);
     } else {
       setData({
         comments: [],
         pagination_parameters: {
           page_number: 0,
           page_size: 0,
           num_pages: 0,
           total_results: 0
         }
       });
       setIsLoading(false);
     }
     }, (err) => {
     setData({
       comments: [],
       pagination_parameters: {
         page_number: 0,
         page_size: 0,
         num_pages: 0,
         total_results: 0
       }
     });
     setIsLoading(false);
     console.error(`Failed to fetch comments. ${err}`);
   })
  }

  return (
    <>
      <div style={{ padding: 14 }} className="course-comments">
        <CreateComment ref={postComment}
                       submitMessage={submitMessage}
                       isLoading={messageIsLoading}/>

        <div className="comment-list">
          {
            !isEmpty(data) && !isLoading && data.comments.map((comment, index) => (
                    <div key={`${comment.id}-${comment.course_id}`}>
                      {comment.id !== null && <Paper className="comments">
                        <CommentsContent data={comment}
                                         course={course}
                                         setDeleteItem={(index) => setDeleteIndexItem(index)}
                                         index={index}
                                         user={user}
                                         deleteSelectedComment={deleteSelectedComment}
                                         lecture={lecture}
                                         schoolId={schoolId}
                        />
                        {isLoading && deleteIndex === index && <div className="delete-comment-loader">
                          <p>Loading...</p>
                        </div>}
                      </Paper>}
                      {index !== data.comments.length - 1 && <hr/>}
                    </div>
                )
            )
          }
        </div>

        {isLoading && <div className="fetching-comments">
          <Loader /> Fetching Comments
        </div>}


        { !isLoading && isEmpty(data.comments) && (<p className="no-comment-available">No Comments Yet</p>)}
      </div>
      {!isEmpty(data) && data.pagination_parameters.total_results > 20 &&
      <ReactPaginate
          previousLabel={"← Previous"}
          nextLabel={"Next →"}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pagination?.num_pages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
      />
      }
    </>
  );
}

export default LectureComments;

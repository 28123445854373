import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

const Loader = () => {
    return (
        <div className='loader flex items-center'>
            <FontAwesomeIcon icon={faCircleNotch} className="spinner" />
        </div>
    );
};

export default Loader;

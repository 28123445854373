class TypedCourseMakerClient {
  client;

  constructor(client) {
    this.client = client;
  }

  getSupportedLanguagesV1() {
    return this.client
      .getSupportedLanguages()
      .then((res) => res.data);
  }

  getSupportedLanguagesV2() {
    return this.client
      .getSupportedLanguagesV2()
      .then((res) => res.data);
  }

  createCodeSubmissionV1(
    sourceCode,
    courseMakerId,
    expectedOutput,
    codeExecutionBackend) {
    return this.client
      .createCodeSubmission(sourceCode, expectedOutput, codeExecutionBackend, courseMakerId)
      .then((res) => res.data);
  }

  createCodeSubmissionV2(
    files,
    courseMakerId,
    expectedOutput,
    runTests) {
    return this.client
      .createCodeSubmissionV2(files, courseMakerId, expectedOutput, runTests)
      .then((res) => res.data);
  }

  getCodeSubmissionResultV1(token, codeExecutionBackend) {
    return this.client
      .getCodeSubmission(token, codeExecutionBackend)
      .then((res) => res.data);
  }

  getCodeSubmissionResultV2(token, courseMakerId) {
    return this.client
      .getCodeSubmissionV2(token, courseMakerId)
      .then((res) => res.data);
  }
}

export class BackwardsCompatibleCodeClient {
  client;
  cachedSupportedLanguagesV1;

  constructor(client) {
    this.client = new TypedCourseMakerClient(client);
  }

  async getCachedSupportedLanguagesV1Async() {
    return this.cachedSupportedLanguagesV1
      || (this.cachedSupportedLanguagesV1 = this.client.getSupportedLanguagesV1());
  }

  async createCodeSubmission(
    files,
    courseMakerId,
    expectedOutput,
    runTests) {

    const supportedLanguagesV1 = await this.getCachedSupportedLanguagesV1Async();
    const langV1 = supportedLanguagesV1.find(lang => lang.id == courseMakerId);

    if(langV1)
      return await this.client
        .createCodeSubmissionV1(
          btoa(files[0].source_code),
          courseMakerId,
          expectedOutput,
          langV1.code_execution_backend);

    return await this.client
      .createCodeSubmissionV2(
        files,
        courseMakerId,
        expectedOutput,
        runTests);
  }

  async getCodeSubmissionResult(token, courseMakerId) {
    const supportedLanguagesV1 = await this.getCachedSupportedLanguagesV1Async();
    const langV1 = supportedLanguagesV1.find(lang => lang.id == courseMakerId);

    if(langV1)
      return await this.client
        .getCodeSubmissionResultV1(token, langV1.code_execution_backend);

    return await this.client
      .getCodeSubmissionResultV2(token, courseMakerId);
  }

  getSupportedLanguages() {
    return Promise.all([
      this.client.getSupportedLanguagesV1(),
      this.client.getSupportedLanguagesV2(),
    ])
      .then(([v1, v2]) =>
        [
          ...v2.map(lang => ({
            coursemaker_id: lang.coursemaker_id,
            title: lang.title,
            ace_editor_mode: lang.ace_editor_mode,
            enable_live_autocompletion: lang.enable_live_autocompletion,
            code_files: lang.code_files,
            supportsMultiFile: true,
            default_test_file_name: lang.default_test_file_name,
            default_file_extension: lang.default_file_extension,
            student_editable_file_path: lang.student_editable_file_path,
          })),
          ...v1.map(lang => ({
            coursemaker_id: lang.id,
            title: lang.title,
            ace_editor_mode: lang.ace_editor_mode,
            enable_live_autocompletion: lang.enable_live_autocompletion,
            code_files: [{path: "__code__", source_code: lang.default_example_source_code}],
            supportsMultiFile: false,
            default_test_file_name: "not_supported"
          }))
        ]);
  }
}
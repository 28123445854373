import React, {forwardRef, useState, useImperativeHandle} from "react";
import TextField from "@material-ui/core/TextField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {isEmpty} from "lodash";
import Loader from "../../../shared-components/Loader";

const CreateComment = forwardRef(({submitMessage, isLoading, cancel}, ref) => {
    const [message, setMessage] = useState("");

    useImperativeHandle(ref, () => ({
        getMessage() {
            return message;
        },
        clearMessage(){
            setMessage("");
        }
    }));

    const setOnChange = (e) => {
        e.preventDefault();
        setMessage(e.target.value);
    };


    return (
    <>
        <div className="reply-box-section">
            <div className="card-content">
                <TextField multiline
                           placeholder="Comment on this lecture ..."
                           value={message}
                           className="text-field"
                           onChange={e => setOnChange(e)}
                           minRows={1}
                           maxRows={4}
                           disabled={isLoading}/>
                <div className="submit-button">
                    <a onClick={() => isEmpty(message) ? {} : submitMessage()}
                       disabled={isLoading || isEmpty(message)}
                       className={`send ${isLoading ? 'loading' : ''} ${isEmpty(message) ? 'disabled' : ''}`}>
                        {isLoading ? <Loader /> :
                            <FontAwesomeIcon icon={faPaperPlane} className="left-icon" />}
                    </a>
                </div>
            </div>
        </div>
    </>
  )
});

export default CreateComment;
